import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const ErrorPage = () => (
  <Layout categories={[]} mainMenu={{ data: [] }} mainSiteUrl="/">
    <SEO />
    
    <div className="main wave">

      <div className="row">
        <div className="column text-center">
          <h1 className="title">404</h1>
          <h3>fakka</h3>
        </div>
      </div>
    </div>
    
  </Layout>
)

export default ErrorPage
